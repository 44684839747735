@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../../design-system/dist/tailwind.css";

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: auto;
}

/* IBM Plex Mono Font Family */
@font-face {
  font-family: "IBM Plex Mono";
  src: url("/assets/fonts/IBMPlex/IBMPlexMono-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("/assets/fonts/IBMPlex/IBMPlexMono-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("/assets/fonts/IBMPlex/IBMPlexMono-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("/assets/fonts/IBMPlex/IBMPlexMono-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("/assets/fonts/IBMPlex/IBMPlexMono-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

/* Mercury Font Family */
@font-face {
  font-family: "Mercury";
  src: url("/assets/fonts/Mercury/Mercury-G1-Roman.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mercury";
  src: url("/assets/fonts/Mercury/Mercury-G4-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-RegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-SemiBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/Gilroy/Gilroy-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Next";
  src: url("/assets/fonts/avenir/avenir-next-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Next";
  src: url("/assets/fonts/avenir/avenir-next-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Next";
  src: url("/assets/fonts/avenir/avenir-next-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("/assets/fonts/avenir/avenir-next-demibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Cera Pro";
  src: url("/assets/fonts/cera/CeraPro-ThinItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("/assets/fonts/Nexa/Nexa-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/assets/fonts/Nexa/Nexa-XBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nexa";
  src: url("/assets/fonts/Nexa/Nexa-Black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@layer utilities {
  .stackedLayer {
    grid-row: 1;
    grid-column: 1;
  }
}
body {
  font-family: "Cera Pro", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.json-view {
  font-family: "IBM Plex Mono", monospace;
  font-size: 12px;
  display: block;
  font-weight: 400;
  line-height: 26px;
  background: rgb(255, 255, 255);
}
.json-view .json-view--property {
  color: var(--json-property);
}
.json-view .json-view--index {
  color: var(--json-index);
}
.json-view .json-view--number {
  color: var(--json-number);
}
.json-view .json-view--string {
  color: var(--json-string);
}
.json-view .json-view--boolean {
  color: var(--json-boolean);
}
.json-view .json-view--null {
  color: var(--json-null);
}

.json-view .jv-indent {
  padding-left: 1em;
}
.json-view .jv-chevron {
  display: inline-block;
  vertical-align: -20%;
  cursor: pointer;
  opacity: 0.4;
  width: 1em;
  height: 1em;
}
:is(.json-view .jv-chevron:hover, .json-view .jv-size:hover + .jv-chevron) {
  opacity: 0.8;
}
.json-view .jv-size {
  cursor: pointer;
  opacity: 0.4;
  font-size: 0.875em;
  font-style: italic;
  margin-left: 0.5em;
  vertical-align: -5%;
  line-height: 1;
}

.json-view :is(.json-view--copy, .json-view--edit),
.json-view .json-view--link svg {
  display: none;
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  cursor: pointer;
}

.json-view .json-view--input {
  width: 120px;
  margin-left: 0.25em;
  border-radius: 4px;
  border: 1px solid currentColor;
  padding: 0px 4px;
  font-size: 87.5%;
  line-height: 1.25;
  background: transparent;
}
.json-view .json-view--deleting {
  outline: 1px solid #da0000;
  background-color: #da000011;
  text-decoration-line: line-through;
}

:is(.json-view:hover, .json-view--pair:hover) > :is(.json-view--copy, .json-view--edit),
:is(.json-view:hover, .json-view--pair:hover) > .json-view--link svg {
  display: inline-block;
}

.json-view .jv-button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.json-view .cursor-pointer {
  cursor: pointer;
}

.json-view svg {
  vertical-align: -10%;
}
.jv-size-chevron ~ svg {
  vertical-align: -16%;
}

.json-view--string {
  word-wrap: break-word;
}

/* Themes */
/* .json-view_a11y {
    color: #545454;
    --json-property: #aa5d00;
    --json-index: #007299;
    --json-number: #007299;
    --json-string: #008000;
    --json-boolean: #d91e18;
    --json-null: #d91e18;
} */
.json-view .json-view--boolean {
  color: #ff1c61;
}
.json-view .json-view--null {
  color: #000000;
}
.json-view_github {
  color: #005cc5;
  --json-property: #005cc5;
  --json-index: #005cc5;
  --json-number: #005cc5;
  --json-string: #032f62;
  --json-boolean: #005cc5;
  --json-null: #005cc5;
}
.json-view_vscode {
  color: #005cc5;
  --json-property: #0451a5;
  --json-index: #0000ff;
  --json-number: #0000ff;
  --json-string: #a31515;
  --json-boolean: #0000ff;
  --json-null: #0000ff;
}
.json-view_atom {
  color: #383a42;
  --json-property: #e45649;
  --json-index: #986801;
  --json-number: #986801;
  --json-string: #50a14f;
  --json-boolean: #0184bc;
  --json-null: #0184bc;
}
.json-view_winter-is-coming {
  color: #0431fa;
  --json-property: #3a9685;
  --json-index: #ae408b;
  --json-number: #ae408b;
  --json-string: #8123a9;
  --json-boolean: #0184bc;
  --json-null: #0184bc;
}

.react-terminal-wrapper {
  background-color: rgba(0, 0, 0, 0.207) !important;
  border: 1px solid #1a1a1a !important;
  border-radius: 0px !important;
}

.react-terminal-wrapper {
  padding-left: 20px !important;
  padding-right: 16px !important;
  padding-top: 20px !important;
  padding-bottom: 12px !important;
}

code {
  white-space: pre-wrap !important;
  padding-right: 40px;
}

.markdown > * {
  all: revert;
  font-size: 14px;
}

.markdown a {
  color: #c084fc;
  text-decoration: underline;
}

.markdown ol {
  /* start with number */
  list-style-type: decimal;
  padding: 0 1rem;
  padding-inline-start: 19px !important;
}

.markdown code {
  white-space: pre-wrap !important;
  padding-right: 40px;
  background: #ffffff08;
  width: 492px;
  display: block;
  overflow: scroll;
  padding: 3px 8px;
  border: 1px solid #2f2f2f;
  border-radius: 12px;
  margin: 9px 0;
  font-size: 12px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background: hsla(212, 94%, 45%, 1);
  border: 0.5px solid #e9d3ff;
  color: #fff;

  transition: all 0.25s ease-in-out;
  cursor: default !important;
  padding: 1px 12px;
  height: 32px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 10px;
}

.custom-file-upload-filled {
  background: #f2f2f2;
  border: 0.5px solid #bfbfbf;
  color: #222222;
}

b,
strong {
  font-weight: 600 !important;
}

.react-select__multi-value__remove {
  background: #e6e5e5;

  path {
    fill: black;
    stroke-width: 0.5px;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: #000;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --chart-1: hsl(221, 86%, 41%);
    --chart-2: hsl(212, 71%, 54%);
    --chart-3: hsl(216, 92%, 60%);
    --chart-4: hsl(210, 68%, 61%);
    --chart-5: hsl(212, 80%, 77%);
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: #000;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: hsl(221, 86%, 41%);
    --chart-2: hsl(212, 71%, 54%);
    --chart-3: hsl(216, 92%, 60%);
    --chart-4: hsl(210, 68%, 61%);
    --chart-5: hsl(212, 80%, 77%);
  }
}

.recharts-tooltip-wrapper {
  background: #fff !important;
  border-radius: 10px !important;
}

.json-view .json-view--property {
  color: #b63c03;
}
